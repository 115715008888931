export const USER_ACTIONS = {
  GET_USER: "GET_USER",
  UPDATING_PROFILE : "UPDATING_PROFILE",
  UPDATE_PROFILE : "UPDATE_PROFILE",
  UPDATE_PROFILE_FAILED : "UPDATE_PROFILE_FAILED",
  UPDATING_PASSWORD : "UPDATING_PASSWORD",
  UPDATE_PASSWORD : "UPDATE_PASSWORD",
  UPDATE_PASSWORD_FAILED : "UPDATE_PASSWORD_FAILED",
  DELETING_USER: "DELETING_USER",
  DELETE_USER : "DELETING_USER",
  DELETING_USER_FAILED: "DELETING_USER_FAILED",
  GETTING_USER: "GETTING_USER",
  GETTING_ALL_USER: "GETTING_ALL_USER",
  GET_ALL_USER: "GET_ALL_USER",
  RESET_USER : "RESET_USER"

};

export const USER_ENDPOINTS = {
  GET_USER: "user/get-user",
  UPDATE_PROFILE : "user/update-profile",
  UPDATE_PASSWORD : "user/update-password",
  DELETE_USER : "user/delete-user",
  GET_ALL_USER: "user/get-all-user",
};

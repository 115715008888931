  import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { USER_ENDPOINTS } from "./user.constant";
import toast, { Toaster } from "react-hot-toast";


export const checkAutoLogin = () => {
  try {
    const jwt = localStorage.getItem("accesstoken");
    if (!jwt) return false;
    const decodedJwt = JSON.parse(atob(jwt.split(".")[1]));
    if (decodedJwt.expiry * 1000 < Date.now()) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const getUser = async () => {
  try {
    const response = await makeRequest(
      USER_ENDPOINTS.GET_USER,
      METHODS.GET,
    );
    if (!response) {
      throw new Error(`Something wen't wrong in getting uer!`);
    }
    return response.data;
  } catch (error) {
    console.error(`Get user > ${error.toString()}`);
    throw error;
  }
};

export const getAllUser = async () => {
  try {
    const response = await makeRequest(
      USER_ENDPOINTS.GET_ALL_USER,
      METHODS.GET,
    );
    if (!response) {
      throw new Error(`Something wen't wrong in getting all users!`);
    }
    return response.data;
  } catch (error) {
    console.error(`Get all user > ${error.toString()}`);
    throw error;
  }
};

export const updateProfile = async (userName:any , fullName:any) => {
  try {
    const response = await makeRequest(
      USER_ENDPOINTS.UPDATE_PROFILE,
      METHODS.POST,
      {userName , fullName}
    );
    if (!response) {
      throw new Error(`Something wen't wrong in updating user profile!`);
    }
    return response.data;
  } catch (error) {
    console.error(`update user profile > ${error.toString()}`);
    throw error;
  }
};

export const updatePassword = async (password:any , oldPassword:any) => {
  try {
    const response = await makeRequest(
      USER_ENDPOINTS.UPDATE_PASSWORD,
      METHODS.POST,
      {password, oldPassword}
    );
    if (!response) {
      throw new Error(`Something wen't wrong in updating user password!`);
    }
    return response.data;
  } catch (error) {
    console.error(`update user password > ${error.toString()}`);
    throw error;
  }
};

export const deleteUser = async (password:any) => {
  try {
    const response = await makeRequest(
      USER_ENDPOINTS.DELETE_USER,
      METHODS.POST,
      {password}
    );
    if (!response) {
      throw new Error(`Something wen't wrong in deleting user!`);
    }
    return response.data;
  } catch (error) {
    console.error(`delete user> ${error.toString()}`);
    throw error;
  }
};

import React, { useState, useEffect } from "react";
import { Link , useNavigate} from "react-router-dom";
import ChatGptIcon from "../assets/chat-gpt.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { register , resetState } from "../app/auth/auth.action";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { checkAutoLogin } from "app/user/user.service";

type SignupPayload = {
  register: (fullName: string, email: string, password: string) => any;
  loading: boolean;
  error: string;
  success: boolean;

};

const SignUp = ({ register, loading, error, success }: SignupPayload) => {
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const dispatch = useDispatch();
const auth = useSelector((state:any)=>state.auth)
const navigate =  useNavigate()




useEffect(() => {
  dispatch(resetState()); 
}, [dispatch]);


  
  useEffect(() => {
    if (success) {
      toast.success("Account created successfully! A verification link has been sent to your email.");
    }
    if (error) {
      toast.error(`Registration failed: ${error}`);
    }
  }, [success,error]);



  const handleRegister = () => {
    if (fullName && email && password) {
      register(fullName, email, password);
    }
  };

  useEffect(() => {
    if(checkAutoLogin()){
      navigate("/")
    }
  },[])

  return (
    <>
      <section className="flex items-center justify-center">
        <div className="h-screen flex flex-col items-center justify-center md:w-[30%] sm:w-[50%] w-[90%]">
          <img src={ChatGptIcon} width={50} alt="gpt-icon" />
          <h1 className="lg:text-3xl text-2xl font-bold my-4">Create an account</h1>
          <div className="flex flex-col w-full">
            <input
              placeholder="Full Name *"
              className="border border-blue-500 rounded px-5 py-3 my-3 outline-none"
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <input
              placeholder="Email Address *"
              className="border border-blue-500 rounded placeholder:text-base px-5 py-3 my-3 outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              placeholder="Password *"
              className="border border-blue-500 placeholder:text-base rounded px-5 py-3 my-3 outline-none"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            className="bg-blue-500 flex justify-center items-center placeholder:text-base w-full rounded-lg p-2 my-5 text-white sm:text-[18px] text-base"
            onClick={handleRegister}
            disabled={loading}
          >
            {loading ? <ClipLoader color="white" size={25}/> : "SignUp"}
          </button>


          <p className="sm:text-base text-sm">
            Already have an account?{" "}
            <Link to="/login" className="text-blue-500">
              Login
            </Link>
          </p>
        </div>
      </section>
      <ToastContainer  />

    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  register: (fullName: string, email: string, password: string) => {
    dispatch(register(fullName, email, password));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

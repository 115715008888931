import React, { useEffect , useState } from "react";
import "../App.css"
import { useDispatch, useSelector } from "react-redux";
import { GetAssistant , DeleteFile} from "app/chats/chats.action";
import { ClipLoader } from "react-spinners";


import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import { BiCommentEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import AddFile from "./AddFile";


const FileManagement = (props: any) => {
    const [open, setOpen] = useState(false)
    const [file, setFile] = useState<File | null>(null);
    
    const handleAddFile = () => {
        setOpen(true)
    } 

    const dispatch = useDispatch();
    const Chats = useSelector((state:any) => state.chats);

    const handleDelete = async (agentFileId:any) => {
        await dispatch(DeleteFile(agentFileId) as any);
        await dispatch(GetAssistant() as any)
    }

    useEffect(() => {
        dispatch(GetAssistant() as any)
    },[])

    return (
        <>
    <AddFile assistantId={Chats?.assistant?.id} open={open} setOpen={setOpen} file={file} setFile={setFile} isAdmin={true} />
    <div className="m-10">
    <div className="flex items-center justify-between">
    <h1 className="text-[35px] font-semibold mb-4" >File Management</h1>
    <button onClick={handleAddFile} className="bg-blue-500 text-white px-5 py-2 rounded-lg font-medium">Add File</button>
    </div>

    <div className="my-3" >
    <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>File Name</Th>
                            <Th>File Type</Th>
                            <Th>Upload Date</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Chats?.assistant?.AgentFiles?.map((file:any , index:any) => {
                            return(
                              <Tr>
                              <Td>icon and name</Td>
                              <Td>pdf</Td>
                              <Td>12/2/2012</Td>
                              <Td>
                              <div style={{display:"flex" , alignItems:"center"}} >
                              <BiCommentEdit className="text-[23px] text-gray-600 cursor-pointer" />
                              {Chats?.loading ? <ClipLoader color="gray" size={20}/>
                              :
                              <AiOutlineDelete onClick={() => handleDelete(file.id)} className="text-[23px] text-red-600 ml-2 cursor-pointer " />
                              }
                              </div>
                              </Td>
                          </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
    </TableContainer>
    </div>
    </div>
        </>
    );
};

export default FileManagement;
import React , {useEffect , useState , useRef } from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import Chat from './Chat';
import '../styles/globals.css'
import MobileSiderbar from './MobileSidebar';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from "react-redux";
import { CreateChat , GetAllChats , GetAssistantUser , Conversation } from 'app/chats/chats.action';


const ChatPage = () => {
    const [isComponentVisible, setIsComponentVisible] = useState(false);

    const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
    };

    let isFirst = useRef(true);
    let hasInitialized = useRef(false);
    const dispatch = useDispatch();
    const chats = useSelector((state: any) => state.chats);
    const navigate = useNavigate();

    const params = useParams();
    const { chatId } = params;

    useEffect(() => {
      dispatch(GetAssistantUser() as any);
    },[])
    
    return(
        <>
        <main className="overflow-hidden w-full h-screen relative flex">
          {isComponentVisible ? (
            <MobileSiderbar toggleComponentVisibility={toggleComponentVisibility} />
          ) : null}
          <div className="dark hidden flex-shrink-0 bg-gray-900 md:flex md:w-[260px] md:flex-col">
            <div className="flex h-full min-h-0 flex-col ">
              <Sidebar toggleComponentVisibility={toggleComponentVisibility} />
            </div>
          </div>
          <Chat toggleComponentVisibility={toggleComponentVisibility} />
        </main>
        </>
    )
}
export default ChatPage;

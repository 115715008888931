import { USER_ACTIONS } from "./user.constant";

const INITIAL_STATE = {
  user: "",
  loading: false,
  success : false,
  profile: true,
  password : true,
  users : [] as any
};
export const user = (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {

      case USER_ACTIONS.GETTING_USER:
        return{
          ...state,
          profile: true,
          password: true
        }
      case USER_ACTIONS.GETTING_ALL_USER:
        return{
          ...state,
          profile: true,
          password: true
        }
      case USER_ACTIONS.GET_USER:
        return{
          ...state,
          user: action.payload
        }
     
        case USER_ACTIONS.GET_ALL_USER:
        return{
          ...state,
          users: action.payload 
        }

      case USER_ACTIONS.UPDATING_PROFILE:
        return {
          ...state,
          loading:true,
          success:false
        }
      case USER_ACTIONS.UPDATE_PROFILE:
        return{
          ...state,
          loading: false,
          success:true,
          user: action.payload
        }
      
      case USER_ACTIONS.UPDATE_PROFILE_FAILED:
        return{
          ...state,
          loading:false,
          success:false,
          profile:false,
          user:action.payload
        }
      case USER_ACTIONS.UPDATING_PASSWORD:
        return {
          ...state,
          loading:true,
          success:false
        }
        case USER_ACTIONS.UPDATE_PASSWORD:
        return{
          ...state,
          loading: false,
          success:true,
          user: action.payload
        }
        case USER_ACTIONS.UPDATE_PASSWORD_FAILED:
        return{
          ...state,
          loading:false,
          success:false,
          password:false,
          user:action.payload
        }
        case USER_ACTIONS.RESET_USER:
          return{
            ...state,
            user: ""
          }
      
    default:
      return state;
  }
};

import React, { useEffect } from "react"
import { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DeleteChat, CreateChat, UpdateAgent , GetAssistant} from "app/chats/chats.action";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { ClipLoader } from "react-spinners";

interface AddFileProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    assistantId: any;
    file?: File;
    setFile?: React.Dispatch<React.SetStateAction<File | null>>;
    isAdmin?: boolean;
    fileInfo? : any;
    setFileInfo? : any;
}

const AddFile: React.FC<AddFileProps> = ({ open, setOpen, assistantId, file, setFile, isAdmin , fileInfo , setFileInfo }) => {

    // const [fileInfo, setFileInfo] = useState<{ name: string; type: string } | null>(null);
    // const [file, setFile] = useState<File | null>(null);
    const allowedFileTypes = ['application/pdf', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const formData = new FormData();
    const dispatch = useDispatch();
    const Chats = useSelector((state: any) => state.chats);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            if (allowedFileTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
                setFileInfo({ name: selectedFile.name, type: selectedFile.type , visible:false });
                if(!isAdmin){setOpen(false);}
            } else {
                setFile(null);
                setFileInfo(null);
                toast.error('Please upload a file in PDF, CSV, or Word format.');
            }
        } else {
            setFile(null);
            setFileInfo(null);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!file) {
            toast.error('Please select a file to upload.');
            return;
        }
        await formData.append('file', file);
        await formData.append('assistantId', assistantId);

        if(!isAdmin){
        setOpen(false);
        }
        else{
            await dispatch(UpdateAgent(formData) as any)
            setOpen(false);
            await dispatch(GetAssistant() as any)
        }
        
    };

    return (
        <>
            <Toaster
                toastOptions={{
                    duration: 2000,
                    style: {
                        background: '#cdcdcd',
                        color: '#fff',
                    },
                    success: {
                        duration: 2000,
                        iconTheme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                    error: {
                        duration: 2000,
                        iconTheme: {
                            primary: 'red',
                            secondary: 'black',
                        },
                    },
                }}
            />
            <Dialog open={open} onClose={setOpen} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-[#212121] text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full max-w-md sm:max-w-lg lg:max-w-xl"
                        >
                            <section className="text-gray-600 body-font bg-white">
                                <div className="container px-5 py-12 mx-auto flex flex-wrap sm:flex-row flex-col">
                                    <div className="flex flex-col mx-auto justify-start items-start flex-wrap mb-10 w-full">
                                        <h2 className="text-[22px] text-gray-600" >Upload File</h2>
                                        <div className="flex justify-center w-full" >
                                            <label htmlFor="file-upload" className="flex flex-col items-center cursor-pointer w-full border-2 border-gray-500 border-dotted mt-3 rounded-xl p-5">
                                                <AiOutlineCloudUpload className="text-[100px] text-gray-600" />
                                                <p className="text-blue-400" >upload files in pdf,word and csv</p>
                                                <input type="file" id="file-upload" className="file-upload-input" onChange={handleFileChange} />
                                            </label>
                                        </div>
                                        {fileInfo && (
                                            <div className="mt-4 text-gray-600 font-medium">
                                                <p>{fileInfo.name}</p>
                                                <p> {fileInfo.type}</p>
                                            </div>
                                        )}

                                        {
                                            (fileInfo && isAdmin) &&
                                            <div className="text-end w-full mt-4" >
                                                <button onClick={handleSubmit} className="bg-blue-600 rounded-xl px-4 py-2 text-white" >
                                                {Chats?.loading ? <ClipLoader color="white" size={25}/>  : "Upload File"}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

        </>
    )
}

export default AddFile
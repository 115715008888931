import React, { useEffect } from 'react';
import { useState } from 'react';
import '../styles/globals.css'
import { Outlet, useNavigate } from 'react-router-dom';
import { RxHamburgerMenu } from "react-icons/rx";
import MobileAdminSiderbar from './MobileAdminSidebar';
import AdminSidebar from './AdminSidebar';
import { useDispatch , useSelector } from 'react-redux';
import { GetUser } from 'app/user/user.action';


const Admin = () => {

    const dispatch = useDispatch();
    const user = useSelector((state:any) => state.user)
    const navigate = useNavigate();

    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const isAdmin = true;

    const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
    };
    
    useEffect(() => {
      dispatch(GetUser() as any);
      if(user?.user?.Role === "Member"){
        navigate("/")
      }
    },[user.user.Role])
    return(
        <>
        <main className="overflow-hidden w-full h-screen relative flex">
          {isComponentVisible ? (
            <MobileAdminSiderbar toggleComponentVisibility={toggleComponentVisibility} />
          ) : null}
          <div className="dark hidden flex-shrink-0 bg-gray-900 md:flex md:w-[260px] md:flex-col">
            <div className="flex h-full min-h-0 flex-col ">
              <AdminSidebar isAdmin={isAdmin} toggleComponentVisibility={toggleComponentVisibility} />
            </div>
          </div>
         
        <div className="flex max-w-full flex-1 flex-col">
        <div className="sticky top-0 z-10 flex items-center justify-between border-b border-white/20 bg-gray-800 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"
            onClick={toggleComponentVisibility}
          >
            <span className="sr-only">Open sidebar</span>
            <RxHamburgerMenu className="h-6 w-6 text-white" />
          </button>
          <h1 className="flex-1 text-end text-base font-normal mx-4">Live100 AI</h1>
        </div>
       
        <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
          <Outlet/>
        </div>
      </div>
        </main>
        </>
    )
}
export default Admin;

import React, { useState, useEffect } from "react";
import ChatGptIcon from '../assets/chat-gpt.png';
import { connect } from "react-redux";
import { resetUserPassword } from "../app/auth/auth.action";
import { resetState } from "../app/auth/auth.action";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';  // Import Toastify CSS
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { checkAutoLogin } from "app/user/user.service";

type ResetPasswordPayload = {
  resetUserPassword: (password: string) => any;
  loading: boolean;
  error: string;
  success: boolean;
};

const ResetPass = ({ resetUserPassword, loading, error, success }: ResetPasswordPayload) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const location = useLocation();
  const nav = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');


  useEffect(() => {
    if (success) {
      toast.success("Password reset successful!");
      nav("/login");
    }
    if (error) {
      toast.error(error);
    }
  }, [success, error]);

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    if (password && confirmPassword) {
      resetUserPassword(password);
    }
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem("accesstoken", token);
    }
  }, [token])

  return (
    <>
      <section className="flex items-center justify-center">
        <div className="h-screen flex flex-col items-center justify-center md:w-[30%] sm:w-[50%] w-[90%]">
          <img src={ChatGptIcon} width={50} alt="gpt-icon" />
          <h1 className="lg:text-3xl text-2xl font-bold my-4">Reset Password</h1>
          <div className="flex flex-col w-full">
            <input
              placeholder="Password *"
              className="border placeholder:text-base outline-none border-blue-500 rounded px-5 py-3 my-3"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              placeholder="Confirm Password *"
              className="border placeholder:text-base outline-none border-blue-500 rounded px-5 py-3 my-3"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button
            className="bg-blue-500 w-full rounded-lg p-2 my-5 text-white sm:text-[18px] text-base"
            onClick={handleResetPassword}
            disabled={loading}  // Disable button while loading
          >
            {loading ? <ClipLoader color="white" size={25} /> : "Change Password"}
          </button>
        </div>
      </section>
      <ToastContainer />  {/* Add ToastContainer for toast messages */}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  resetUserPassword: (password: string) => {
    dispatch(resetUserPassword(password));
  },
  resetState: () => {
    dispatch(resetState());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPass);

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FiSend } from "react-icons/fi";
import { BsChevronDown, BsPlusLg } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
// import useAnalytics from '../hooks/useAnalytics.tsx'
import useAutoResizeTextArea from "../hooks/useAutoResizeTextArea";
import Message from "./Message";
import { DEFAULT_OPENAI_MODEL } from "../shared/Constants";
import "../App.css"
import { useDispatch, useSelector } from "react-redux";
import { Conversation, GetAllAgents, setAgentName, setConverstions, UpdateChatName, GetAllChats, GetAssistant } from "app/chats/chats.action";
import { GetAllUser } from "app/user/user.action";
import { Socket } from "Socket/socket";
import { useNavigate } from "react-router-dom";
import { BiCommentEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'

const UserManagement = (props: any) => {

    const dispatch = useDispatch();
    const User = useSelector((state:any) => state.user);
    const Chats = useSelector((state:any) => state.chats);

    useEffect(() => {
        dispatch(GetAllUser() as any)
        dispatch(GetAssistant() as any)
    },[])

    return (
    <div className="m-10">
    <h1 className="text-[35px] font-semibold mb-4" >User Management</h1>

    <div className="my-3" >
    <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>User Name</Th>
                            <Th>Email Address</Th>
                            <Th>Role</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            User?.users?.map((user:any , index:any) => {
                                return(
                                    <Tr key={index} >
                                    <Td>{user.fullName}</Td>
                                    <Td>{user.email}</Td>
                                    <Td>{user.Role}</Td>
                                    <Td>
                                    <div style={{display:"flex" , alignItems:"center"}} >
                                    <BiCommentEdit className="text-[23px] text-gray-600 cursor-pointer" /> <AiOutlineDelete className="text-[23px] text-red-600 ml-2 cursor-pointer " />
                                    </div>
                                    </Td>
                                </Tr>
                                )
                            })
                        }
                       
                    </Tbody>
                </Table>
    </TableContainer>
    </div>
    </div>
    );
};

export default UserManagement;